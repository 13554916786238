import { __awaiter, __decorate, __generator, __metadata } from "tslib";
import { injectable } from 'core/decorators';
import { StudentEnrolmentService } from 'enrolment/services';
import { RestClient } from 'core/services/rest-client';
export var PreviewStudentEnrolmentService = /** @class */ (function () {
    function PreviewStudentEnrolmentService(restClient) {
        this.previewEnrolment = getDefaultEnrolment();
        this.studentEnrolmentService = new StudentEnrolmentService(restClient);
    }
    PreviewStudentEnrolmentService.prototype.getActiveEnrolment = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, Promise.resolve(this.previewEnrolment)];
            });
        });
    };
    PreviewStudentEnrolmentService.prototype.createEnrolment = function (_studentId, offer) {
        this.previewEnrolment = this.previewEnrolment || getDefaultEnrolment();
        this.previewEnrolment.offerId = offer.id;
        this.previewEnrolment.isAdvancingAdmissionsOffer = offer.isAdvancingAdmissionsOffer;
        this.previewEnrolment.courseSpkCode = offer.spkCode;
        this.previewEnrolment.courseSpkNumber = offer.spkNumber;
        this.previewEnrolment.courseSpkVersion = offer.spkVersion;
        return Promise.resolve(this.previewEnrolment);
    };
    PreviewStudentEnrolmentService.prototype.saveStudyPlanComponents = function (_userId, _offerId, studyPlanComponents) {
        if (!this.previewEnrolment)
            return Promise.resolve([]);
        this.previewEnrolment.studyPlanComponents = studyPlanComponents;
        return Promise.resolve(this.previewEnrolment.studyPlanComponents || []);
    };
    PreviewStudentEnrolmentService.prototype.getStudyPlanComponents = function () {
        if (!this.previewEnrolment)
            return Promise.resolve([]);
        return Promise.resolve(this.previewEnrolment.studyPlanComponents || []);
    };
    PreviewStudentEnrolmentService.prototype.fetchUserActiveEnrolment = function (userId) {
        return this.studentEnrolmentService.getActiveEnrolment(userId);
    };
    PreviewStudentEnrolmentService.prototype.resetPreviewEnrolment = function () {
        this.previewEnrolment = getDefaultEnrolment();
    };
    PreviewStudentEnrolmentService.prototype.saveVaccinationEvidence = function (_userId, hasSubmittedVaccineEvidence) {
        if (this.previewEnrolment)
            this.previewEnrolment.hasSubmittedVaccineEvidence = hasSubmittedVaccineEvidence;
        return Promise.resolve(this.previewEnrolment);
    };
    PreviewStudentEnrolmentService.prototype.saveUsiSubmitted = function (_userId, hasSubmittedUsi) {
        if (this.previewEnrolment)
            this.previewEnrolment.hasSubmittedUsi = hasSubmittedUsi;
        return Promise.resolve(this.previewEnrolment);
    };
    PreviewStudentEnrolmentService.prototype.saveCareerSurveyCompleted = function (_userId, hasCompletedCareerSurvey) {
        if (this.previewEnrolment)
            this.previewEnrolment.hasCompletedCareerSurvey = hasCompletedCareerSurvey;
        return Promise.resolve(this.previewEnrolment);
    };
    PreviewStudentEnrolmentService = __decorate([
        injectable(),
        __metadata("design:paramtypes", [RestClient])
    ], PreviewStudentEnrolmentService);
    return PreviewStudentEnrolmentService;
}());
function getDefaultEnrolment() {
    return {
        studentId: '16003163',
        offerId: 1,
        courseSpkCode: 'B-COMM',
        courseSpkNumber: 319266,
        courseSpkVersion: 1,
        ecafRequired: false,
        ecafApproved: false,
        offerStatus: 'Accepted',
        studyPlanExpanded: false,
        hasEnrolledUnits: false,
        hasSubmittedVaccineEvidence: false,
        hasSubmittedUsi: false,
        classRegistrationRequired: true,
        studyPlanComponents: null,
        conditions: [],
        courseHasStudyComponents: false,
        liabilityCategoryCode: '5',
        hasCompletedCareerSurvey: false,
        isAdvancingAdmissionsOffer: true,
        advancingAdmissionsOfferStatus: 'Accepted'
    };
}
